import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import PhoneInput from 'react-phone-number-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import './landingForm.scss'

import { useForm } from '../../../hooks/useForm'
import {
  validateEmail,
  validateContent,
  validatePhoneNumber
} from '../../../helpers/validateFormsFields'
import postForm from '../../../services/postForm'
import geolocation from '../../../services/geolocation'
import countryData from '../../../services/contryData'
import colegiosList from '../../../data/colegios'

export const LandingForm = ({ formTitle, color = null, interestType, interestReceiver }) => {
  const [ values, handleInputChange, reset ] = useForm({})
  const [ validated, setValidated ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ alertForm, setAlertForm ] = useState(false)
  const [ alertFormError, setAlertFormError ] = useState(false)
  
  // Seteando Pais del Usuario segun su ubicacion
  const [ userCountry, setUserCountry ] = useState('');

  // Abreviatura del pais segun ISO 3166-1 alpha2
  const [ isoCountry, setIsoCountry ] = useState('')

  // Seteando Codigo Pais
  const [ countryCode, setCountryCode ] = useState('');
    
  const { name, email, phone, dni, colegio, anioEgreso, country, province, locality, ubicacionCursado, ...rest } = values

  const valuesRequired = {
    subject: 'Consulta Landing',
    agent: interestReceiver?.receiver,
    name,
    email,
    phone: phone !== undefined ? phone : countryCode,
    dni: dni,
    colegio: colegio,
    anioEgreso: anioEgreso,
    country: country,
    province: province,
    locality: locality,
    ubicacion: ubicacionCursado,
    type: {
      option: interestType?.option,
      type: interestType?.type
    },
    content: rest
  }

  // Obteniendo Ubicacion, IsoPais y CodigoPais del Usuario
  useEffect(() => {
    async function fetchData() {
      try {
        const country = await geolocation();
        setUserCountry(country);

        const dataCountry = await countryData(country)
        setIsoCountry(dataCountry.isoCountry)
        setCountryCode(dataCountry.countryCode)

        // Actualizar el estado values con el país obtenido
        handleInputChange({
          target: { name: 'country', value: country }
        });
      } catch (error) {
        console.error('Error al obtener el país:', error);
        setUserCountry("Argentina");
        setIsoCountry("AR");
        setCountryCode("54");

        // Actualizar el estado values con el país por defecto
        handleInputChange({
          target: { name: 'country', value: 'Argentina' }
        });
      } 
    }
      
    fetchData()
  }, []);


  // console.log("=============================");
  // console.log("userCountry " + userCountry);
  // console.log("IspCountry " + isoCountry);
  // console.log("CountryCode " + countryCode);
  // console.log("ValuesRequired " + valuesRequired.phone);
  // console.log("=============================");

  // Cambiando codigo pais al seleccionar otra bandera
  const handlePhoneInputChange = (newCountryCode) => {
    setCountryCode(newCountryCode)
    values.phone = newCountryCode // Reset Phone value
  }


  // Validando los campos
  useEffect(() => {
    if (
      values.name?.length > 2 &&
      validateEmail(values.email) &&
      validatePhoneNumber(values.phone) &&
      validateContent(1, valuesRequired.dni) &&
      validateContent(1, valuesRequired.colegio) &&
      validateContent(1, valuesRequired.anioEgreso) &&
      validateContent(1, valuesRequired.country) &&
      validateContent(1, valuesRequired.province) &&
      validateContent(1, valuesRequired.locality) &&
      validateContent(1, valuesRequired.ubicacion)
    ) {
      return setValidated(true)
    } else {
      return setValidated(false)
    }
  }, [values])


  // Submit Event
  const handleSubmit = async (event) => {
    const formulario = event.currentTarget
    setLoading(true)
    event.preventDefault()

    try {
      const sendForm = await postForm(valuesRequired)

      if (sendForm.status) {
        setLoading(false)
        formulario.reset()
        reset()
        setAlertForm(true)
        setAlertFormError(false)
      } else {
        setLoading(false)
        setAlertForm(false)
        setAlertFormError(true)
      }
    } catch (error) {
      setLoading(false)
      setAlertForm(false)
      setAlertFormError(true)
    }
  }

  const handleClick = () => {
    setAlertForm(false)
  }

  const handleClickError = () => {
    setAlertFormError(false)
  }

  return (
    <div className="container">
      <div className="landing-form">
        <div className="w-100 position-relative">
          {alertForm && (
            <div className="form-alert container">
              <div className="send-ok">
                <FontAwesomeIcon icon={faCheckCircle} color={'green'} />
                <span>Consulta exitosa</span>
                <button className="button-form" onClick={handleClick}>
                  OK
                </button>
              </div>
            </div>
          )}

          {alertFormError && (
            <div className="form-alert container">
              <div className="send-error">
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span>
                  Error al enviar el formulario, intente nuevamente más tarde!
                </span>
                <button className="button-form" onClick={handleClickError}>
                  OK
                </button>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              {formTitle && (
                <h4 className="pb-2 ucc-text-blue font-weight-bold" style={ color && { color: color }}>
                  {formTitle}
                </h4>
              )}
              <Form
                className="form-format"
                noValidate
                onSubmit={handleSubmit}
                validated={validated}
                id="form"
              >
                <Row>
                  {/* Name and Last Name */}
                  <Col lg={12} xs={12} key={'form-name'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'Nombre y apellido *'}
                      </Form.Label>
                      <Form.Control
                        name={'name'}
                        type="text"
                        className="border-celeste"
                        minLength="2"
                        autoComplete="off"
                        required
                        placeholder={'Completá tus datos'}
                        onChange={handleInputChange}
                        isValid={values.name?.length > 2}
                        isInvalid={
                          values.name?.length > 0 && values.name?.length <= 2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingrese un dato por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Email */}
                  <Col lg={12} xs={12} key={'form-email'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'Correo electrónico *'}
                      </Form.Label>
                      <Form.Control
                        name={'email'}
                        type="email"
                        autoComplete="off"
                        required
                        placeholder={'correo@correo.com'}
                        onChange={handleInputChange}
                        isValid={validateEmail(values.email)}
                        isInvalid={
                          values.email?.length > 0 &&
                          !validateEmail(values.email)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingrese un mail válido por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                  {/* Phone */}
                  <Col lg={12} xs={12} key={'form-phone'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'Celular *'}
                      </Form.Label>
                      <div className="d-flex">
                        <PhoneInput
                          autoComplete="off"
                          international
                          className="custom-phone-input phone-num"
                          defaultCountry={isoCountry}
                          onChange={handlePhoneInputChange}
                        />

                        <Form.Control
                          name={'phone'}
                          type="text"
                          className="phone-input"
                          value={
                            countryCode === values 
                              ? countryCode 
                              : valuesRequired.phone
                          }
                          onChange={handleInputChange}
                          autoComplete="off"
                          required
                          isValid={validatePhoneNumber(valuesRequired.phone)}
                          isInvalid={
                            values.phone?.length > 2 &&
                            !validatePhoneNumber(valuesRequired.phone)
                          }
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  {/* DNI */}
                  <Col lg={12} xs={12} key={'form-dni'}>
                    <Form.Group>
                      <Form.Label>{'DNI *'}</Form.Label>
                      <Form.Control
                        name={'dni'}
                        type="text"
                        required={true}
                        onChange={handleInputChange}
                        className="text-black"
                        minLength={7}
                        maxLength={9}
                        isValid={
                          values.dni?.length >= 7 && values.dni?.length <= 9 
                        }
                        isInvalid={
                          values.dni?.length > 9 || values.dni?.length < 7
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Complete con un DNI correcto
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Colegio */}
                  <Col lg={12} xs={12} key={'form-colegio'}>
                    <Form.Group>
                      <Form.Label>{'Colegio *'}</Form.Label>
                      <input 
                        name={'colegio'}
                        className={`form form-control ${(values.colegio) ? ((values.colegio.length > 3) ? 'is-valid' : 'is-invalid'):('')}`} 
                        list="listColegios" 
                        onChange={handleInputChange}
                      />
                      <datalist id="listColegios" className='form'>
                        {colegiosList.map((e, i) => {
                          return (
                            <option
                              value={`${e.nombre}`}
                              key={`${e.nombre}`}
                            >
                            </option>
                          );
                        })}
                      </datalist>
                    </Form.Group>
                  </Col>

                  {/* Año de Egreso */}
                  <Col lg={12} xs={12} key={'form-graduation-year'}>
                    <Form.Group>
                      <Form.Label>{'Seleccioná tu año de egreso'}</Form.Label>
                      <Form.Control
                        name={'anioEgreso'}
                        as="select"
                        onChange={handleInputChange}
                        required={true}
                        defaultValue={'-1'}
                        className="form-control"
                        isValid={
                          values.anioEgreso !== undefined
                        }
                      >
                        <option value="-1" disabled>
                          {''}
                        </option>
                        <option value={new Date().getFullYear() + 2}>
                          {new Date().getFullYear() + 2}
                        </option>
                        <option value={new Date().getFullYear() + 1}>
                          {new Date().getFullYear() + 1}
                        </option>
                        <option value={new Date().getFullYear()}>
                          {new Date().getFullYear()}
                        </option>
                        <option value={new Date().getFullYear() - 1}>
                          {new Date().getFullYear() - 1}
                        </option>
                        <option value={new Date().getFullYear() - 2}>
                          {new Date().getFullYear() - 2}
                        </option>
                        <option value={new Date().getFullYear() - 3}>
                          {new Date().getFullYear() - 3}
                        </option>
                        <option value={2000}>
                          {'Egresado en otro año'}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Seleccione su año de egreso por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Pais */}
                  <Col lg={12} xs={12} key={'form-country'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'País *'}
                      </Form.Label>
                      <Form.Control
                        name={'country'}
                        type="country"
                        autoComplete="off"
                        defaultValue={userCountry}
                        onInput={(e) => {
                          setUserCountry(e.target.value);
                          handleInputChange(e);
                        }}
                        required
                        isValid
                        isInvalid={
                          values.country?.length >= 0 && values.country?.length <= 2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingrese un pais válido por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Provincia */}
                  <Col lg={12} xs={12} key={'form-province'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'Provincia *'}
                      </Form.Label>
                      <Form.Control
                        name={'province'}
                        type="text"
                        className="border-celeste"
                        minLength="2"
                        autoComplete="off"
                        required
                        onChange={handleInputChange}
                        isValid={values.province?.length > 2}
                        isInvalid={
                          values.province?.length > 0 && values.province?.length <= 2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingresá una Provincia válida por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                  {/* Localidad */}
                  <Col lg={12} xs={12} key={'form-locality'}>
                    <Form.Group controlId={'validationCustom'} className="mb-2">
                      <Form.Label className="contactForm-label">
                        {'Localidad *'}
                      </Form.Label>
                      <Form.Control
                        name={'locality'}
                        type="text"
                        className="border-celeste"
                        minLength="2"
                        autoComplete="off"
                        required
                        onChange={handleInputChange}
                        isValid={values.locality?.length > 2}
                        isInvalid={
                          values.locality?.length > 0 && values.locality?.length <= 2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingresá una Localidad válida por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                 
                  {/* Ubicacion para Cursado */}
                  <Col lg={12} xs={12} key={'form-ubicacion-cursado'}>
                    <Form.Group controlId={'validationCustom'}>
                      <Form.Label>
                        {'Seleccioná donde querés cursar *'}
                      </Form.Label>
                      <Form.Control
                        name={'ubicacionCursado'}
                        as="select"
                        onChange={handleInputChange}
                        required
                        defaultValue={'-1'}
                        className="select-contact-form"
                        isValid={
                          values.ubicacionCursado !== undefined
                        }
                      >
                        <option value="-1" disabled>
                          {''}
                        </option>
                        <option value="Córdoba">
                          {"Córdoba"}
                        </option>
                        <option value="UCC Rio Cuarto">
                          {"UCC Rio Cuarto"}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Seleccioná donde te gustaría cursar por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} xs={12} key={'form-submit'}>
                    <Form.Group className="mb-2">
                      <div className="d-flex justify-content-center align-items-center pt-2">
                        <div>
                          <button
                            className='btn btn-sm btn-landing'
                            type="submit"
                            style={{
                              cursor:
                                !validated || loading
                                  ? 'not-allowed'
                                  : 'pointer',
                              backgroundColor: color ? color : "#182c49"
                            }}
                            disabled={!validated || loading}
                          >
                            {!loading ? (
                              <span className="px-2">Enviar</span>
                            ) : (
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="pr-2">Enviando</span>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LandingForm.propTypes = {
  formTitle: PropTypes.string,
  interestReceiver: PropTypes.object,
  interestType: PropTypes.object.isRequired
}
